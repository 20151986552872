import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UmbrellaState } from 'types/redesignStore'

export const initialState: UmbrellaState = {
  selectedNestedAnswersId: '',
  removedElectionId: '',
  resetFormValues: false,
  umbrellaRequiredActions: false,
}

const umbrellaSlice = createSlice({
  name: 'umbrella',
  initialState,
  reducers: {
    setSelectedNestedAnswersId(state, { payload }: PayloadAction<string>) {
      state.selectedNestedAnswersId = payload
    },
    setRemovedElectionId(state, { payload }: PayloadAction<string>) {
      state.removedElectionId = payload
    },
    setResetFormValues: (state, { payload }: PayloadAction<boolean>) => {
      state.resetFormValues = payload
    },
    setUmbrellaRequiredActions: (state, { payload }: PayloadAction<boolean>) => {
      state.umbrellaRequiredActions = payload
    },
  },
})

export const { setSelectedNestedAnswersId, setRemovedElectionId, setResetFormValues, setUmbrellaRequiredActions } =
  umbrellaSlice.actions
export default umbrellaSlice.reducer
