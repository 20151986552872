import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { NotificationsState } from 'types/redesignStore'

export const initialState: NotificationsState = {
  notificationPanelOpen: false,
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotificationPanelOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.notificationPanelOpen = payload
    },
  },
})

export const { setNotificationPanelOpen } = notificationsSlice.actions
export default notificationsSlice.reducer
